export const disciplineAllegations = [
  {
    vb: 'Mapleview Developments Ltd.',
    link: 'https://obd.hcraontario.ca/profile/B46303',
    referralDate: 'November 10, 2023',
    nextSteps:
      'Proceedings stayed due to Receivership Order dated March 21, 2024'
  },
  {
    vb: 'LJM Developments (Stoney Creek) Inc.',
    link: 'https://obd.hcraontario.ca/profile/B46112',
    referralDate: 'January 30, 2024',
    nextSteps: 'Hearing: October 2024'
  },
  {
    vb: 'Briarwood (Angus) Ltd.',
    link: 'https://obd.hcraontario.ca/profile/B48253',
    referralDate: 'April 11, 2024',
    nextSteps: 'Licensee Motion: November 2024'
  },
  {
    vb: 'Briarwood Estates (Sutton) Ltd.',
    link: 'https://obd.hcraontario.ca/profile/B43292',
    referralDate: 'April 11, 2024',
    nextSteps: 'Licensee Motion: November 2024'
  },
  {
    vb: '863195 Ontario Limited',
    link: 'https://obd.hcraontario.ca/profile/B47464',
    referralDate: 'April 11, 2024',
    nextSteps: 'Licensee Motion: November 2024'
  },
  {
    vb: '2401886 Ontario Limited',
    link: 'https://obd.hcraontario.ca/profile/B45312',
    referralDate: 'April 11, 2024',
    nextSteps: 'Licensee Motion: November 2024'
  }
]

export const disciplineDecisions = [
  {
    vb: '1332741 Ontario Inc.',
    link: 'https://obd.hcraontario.ca/profile/B28375',
    decisionDate: 'November 20, 2022',
    decisionTxt:
      'NHCLA - Code of Ethics - s. 2 Licencee to ensure compliance; s.9 Intimidation coercion etc.; and s. 16 Obstruction of complaints',
    outcomeTxt:
      '1332741 Ontario Inc. o/a Patten Homes 2000 is ordered to pay a fine of $10,000 and to arrange for its employees to complete the following education courses: Customer Service and Tarion Requirements, Legal Issues in Housing'
  }
]
