import React from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { useMediaQuery } from 'react-responsive'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'

import Layout from '../../../components/Layouts/layout'
import SEO from '../../../components/seo'
import Alert from '../../../components/Alert/Alert'

import {
  disciplineAllegations,
  disciplineDecisions
} from '../../../utils/discipline'
import { orderByDate } from '../../../utils/sorting'
import { FaExternalLinkAlt } from 'react-icons/fa'

const DiscliplinePage = () => {
  const intl = useIntl()
  const isScreenSm = useMediaQuery({ maxWidth: 1200 })
  const orderedAllegations = orderByDate(disciplineAllegations, 'referralDate')
  const orderedDecisions = orderByDate(disciplineDecisions, 'decisionDate')

  return (
    <Layout
      header={<span className='bold'>Discipline &amp; Appeals</span>}
      breadcrumb={{
        text: <FormattedMessage id='nav.regActivites' />,
        path: '/licensing-compliance/regulatory-activites-enforcement/'
      }}
    >
      <SEO
        description={intl.formatMessage({
          id: 'metaTags.regActivitiesDescription'
        })}
        lang={intl.locale}
        title={intl.formatMessage({ id: 'nav.notices' })}
      />
      <Container>
        {intl.locale === 'fr' && (
          <Row>
            <Col>
              <Alert header='Attention'>
                <p lang='fr'>
                  Pour les services en français, veuillez contacter le Service
                  des licences et à la clientèle de l’Office de réglementation
                  de la construction des logements au 416-487-HCRA (4272) ou{' '}
                  <a href='mailto:info@hcraontario.ca'>info@hcraontario.ca</a>
                </p>
              </Alert>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <h2>Allegations</h2>
            <p>
              If you are interested in attending any upcoming virtual hearings,
              please contact <a href='/'>hearings@hcraontario.ca</a>.
            </p>
            {isScreenSm ? (
              <>
                {orderedAllegations.map((allegation) => (
                  <div className='tile'>
                    <p>
                      <a href={allegation.link} className='linkHeader'>
                        <FaExternalLinkAlt />
                        {allegation.vb}
                      </a>
                      <br />
                      <b>Referral Date:</b> {allegation.referralDate}
                      <br />
                      <b>Next Steps:</b>{' '}
                      {allegation.nextSteps ? allegation.nextSteps : '---'}
                    </p>
                  </div>
                ))}
              </>
            ) : (
              <div>
                <table>
                  <thead>
                    <tr>
                      <th>Licensee</th>
                      <th>Referral Date</th>
                      <th>Next Steps</th>
                    </tr>
                  </thead>

                  <tbody>
                    {orderedAllegations.map((allegation) => (
                      <tr>
                        <td>
                          <a href={allegation.link} className='linkHeader'>
                            <FaExternalLinkAlt />
                            {allegation.vb}
                          </a>
                        </td>
                        <td>{allegation.referralDate}</td>
                        <td>
                          {allegation.nextSteps ? allegation.nextSteps : '---'}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </Col>
        </Row>

        <Row className='pageRow'>
          <Col>
            <h2>Decisions</h2>
            {isScreenSm ? (
              <>
                {orderedDecisions.map((decision) => (
                  <div className='tile'>
                    <p>
                      <a href={decision.link} className='linkHeader'>
                        <FaExternalLinkAlt />
                        {decision.vb}
                      </a>
                      <br />
                      <b>Decision Date:</b> {decision.decisionDate}
                      <br />
                      <b>Decision:</b> {decision.decisionTxt}
                      <br />
                      <b>Outcome:</b> {decision.outcomeTxt}
                      <br />
                      <b>Under Appeal:</b>{' '}
                      {decision.appealed ? decision.appealed : '---'}
                    </p>
                  </div>
                ))}
              </>
            ) : (
              <div>
                <div className='overflowContainer'>
                  <table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Decision Date</th>
                        <th>Decision</th>
                        <th>Outcome</th>
                        <th>Under Appeal</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderedDecisions.map((decision) => (
                        <tr className='topAlign'>
                          <td style={{ minWidth: `16rem` }}>
                            <a href={decision.link} className='linkHeader'>
                              <FaExternalLinkAlt />
                              {decision.vb}
                            </a>
                          </td>
                          <td>{decision.decisionDate}</td>
                          <td style={{ minWidth: `24rem` }}>
                            {decision.decisionTxt}
                          </td>
                          <td style={{ minWidth: `30rem` }}>
                            {decision.outcomeTxt}
                          </td>
                          <td>
                            {decision.appealed ? decision.appealed : '---'}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default DiscliplinePage
